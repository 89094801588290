@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .compareCell {
    @apply flex items-center justify-center text-center text-white rounded-sm border border-wgray bg-wgray bg-opacity-60 hover:bg-opacity-75 hover:cursor-default min-h-16;
    animation: spin-cell 0.5s ease backwards;

  }

  /* body {
    position: relative;
  }

  body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/temp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: brightness(30%) grayscale(50%) blur(1px);
    z-index: -1;
  } */



  #app-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/public/temp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: brightness(30%) grayscale(50%) blur(1px);
    transform: scale(1.05);
    z-index: -1;
  }



  .blue {
    @apply bg-wred bg-opacity-50;
  }

  .red {
    @apply bg-wred bg-opacity-50;
  }

  .yellow {
    @apply bg-wred bg-opacity-50;
  }

  .green {
    @apply bg-wgreen bg-opacity-50;
  }

  .guessed {
    animation: spin-cell 0.5s ease backwards;
  }

  .confetti {
    position: fixed;
    top: 40%;
    left: 50%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;
  }
}

#row>div:nth-child(2) {
  animation-delay: 0.3s;
}

#row>div:nth-child(3) {
  animation-delay: 0.6s;
}

#row>div:nth-child(4) {
  animation-delay: 0.9s;
}

#row>div:nth-child(5) {
  animation-delay: 1.2s;
}

#row>div:nth-child(6) {
  animation-delay: 1.5s;
}

#privacy>h1 {
  @apply text-wyellow;
}

#privacy>h2 {
  @apply text-wyellow text-lg;
}

#privacy>p>a {
  @apply text-blue-500 hover:underline;
}

#about>p>a {
  @apply text-blue-500 hover:underline;
}


#howTo>h3 {
  @apply text-wyellow text-lg underline;
}


#howTo>h4 {
  @apply text-wyellow text-base font-medium;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s
}

@keyframes spin-cell {
  0% {
    transform: rotateX(0deg);
    opacity: 0;
  }

  45% {
    transform: rotateX(90deg);
    opacity: 0;
  }

  55% {
    transform: rotateX(90deg);
    opacity: 50;
  }

  100% {
    transform: rotateX(0);
    opacity: 100
  }
}

@media screen and (max-width: 768px) {
  #app-background {
    background-size: cover;
    filter: brightness(15%) grayscale(50%) blur(1px);
  }

  .compareCell {
    @apply bg-opacity-75;
  }

  .blue {
    @apply bg-opacity-75;
  }

  .red {
    @apply bg-opacity-75;
  }

  .yellow {
    @apply bg-opacity-75;
  }

  .green {
    @apply bg-opacity-75;
  }
}